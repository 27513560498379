import { CheckedSuccess, ErrorMark } from "src/assets/icons/others";

const LCProcessStatus = ({
	isSuccess,
	successTitle,
	failTitle,
	successSubtitle,
	failSubtitle,
	successActionComponent,
	failActionComponent,
}: {
	isSuccess: boolean;
	successTitle: string;
	failTitle: string;
	successSubtitle: string | JSX.Element;
	failSubtitle: string | JSX.Element;
	successActionComponent?: JSX.Element;
	failActionComponent?: JSX.Element;
}) => {
	return (
		<div className="space-y-5">
			<div className="w-20 h-20 mx-auto">{isSuccess ? <CheckedSuccess /> : <ErrorMark />}</div>
			<div className="text-center space-y-2">
				<h1 className="font-semibold text-lg">{isSuccess ? successTitle : failTitle}</h1>
				<p className="text-sm w-full mx-auto max-w-[284px]">{isSuccess ? successSubtitle : failSubtitle}</p>
			</div>
			<div className="flex items-center justify-center w-full">{isSuccess ? successActionComponent : failActionComponent}</div>
		</div>
	);
};

export default LCProcessStatus;
