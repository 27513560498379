import { DropDownDatatype } from ".";

interface ILCDropdownListItem {
	option: DropDownDatatype;
	onSelect?: (v: DropDownDatatype) => void;
	logo: any;
}

const LCDropdownListItem = ({ option, onSelect, logo }: ILCDropdownListItem) => {
	const handleSelectedItem = () => {
		onSelect && onSelect(option);
	};
	return (
		<button onClick={handleSelectedItem} className="flex items-center justify-between w-full py-2 pl-8 pr-5 bg-white outline-none">
			<div className="grid items-center gap-x-2" style={{ gridTemplateColumns: "auto auto" }}>
				{!!logo && (
					<div className={`w-5 h-5 ${!logo?.length && "bg-black-20"} rounded-full flex items-center justify-center`}>
						<img src={logo} alt="" className="w-full h-full rounded-full" />
					</div>
				)}
				<div>
					<p className="text-xs text-left uppercase text-black-80 grotesk">
						<span className="text-[#171717] text-sm font-medium">{option?.name}</span>
					</p>
				</div>
			</div>
			<i className="text-black ri-arrow-right-s-line ri-md"></i>
		</button>
	);
};

export default LCDropdownListItem;
