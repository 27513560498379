/* eslint-disable react/display-name */
import React from "react";
import PinInput from "react-pin-input";

const LCInputPin = ({ length, onChange, secret = true }: { length: number; onChange: (v: string) => void; secret?: boolean }) => {
	return (
		<PinInput
			length={length}
			initialValue=""
			secret={secret}
			onChange={onChange}
			type="numeric"
			inputMode="number"
			style={{
				display: "flex",
				justifyContent: "center",
			}}
			inputStyle={{
				color: "#171717",
				width: "32px",
				height: "32px",
				borderRadius: "4px",
				backgroundColor: "#F2F2F2",
				border: "none",
				margin: "4px",
			}}
			inputFocusStyle={{}}
			onComplete={(value, index) => {
				onChange(value);
			}}
			autoSelect={true}
			regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
		/>
	);
};

export default LCInputPin;
