import { MouseEventHandler } from "react";

const LCBack = ({ text = "Back", handleClick }: { text?: string; handleClick: MouseEventHandler<HTMLButtonElement> }) => {
	return (
		<button onClick={handleClick} className="inline-block">
			<div className="flex items-center text-black">
				<i className="ri-arrow-left-s-line ri-xl"></i>
				<p className="font-semibold text-sm">{text}</p>
			</div>
		</button>
	);
};

export default LCBack;
