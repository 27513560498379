import LCBack from "../components/LCBack";

const LCNavbar = ({
	hasBack = false,
	hasCompanyName = false,
	handleBackClick = () => {},
}: {
	hasBack?: boolean;
	handleBackClick?: () => void;
	hasCompanyName?: boolean;
}) => {
	return (
		<>
			<div className="w-full">
				<div className={`flex pt-2 mb-3 ${!hasBack && !hasCompanyName ? "justify-end" : "justify-between"} items-center w-full`}>
					{hasBack && <LCBack handleClick={handleBackClick} />}
				</div>
			</div>
		</>
	);
};

export default LCNavbar;
