import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { sendOTP } from "src/api";
import LCButton from "src/components/LCButton";
import LCInput from "src/components/LCInput";
import LCTitleSubtitle from "src/components/LCTitleSubtitle";
import { INITIATE_PURCHASE, VERIFICATION_CODE } from "src/constants/screen";
import LCNavbar from "src/containers/LCNavbar";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";
import { getCountryPhoneCode } from "src/utility";

const ProvidePhone = () => {
	const { setCurrentScreen, setErrorMessage } = useAppContext();
	const { transactionRef, info } = useTransactionContext();

	const { mutate, isLoading } = useMutation(sendOTP, {
		onSuccess: () => setCurrentScreen(VERIFICATION_CODE),
		onError: ({ message }: any) => setErrorMessage(message),
	});

	const [phoneNumber, setPhoneNumber] = useState("");

	const handleBackClick = () => setCurrentScreen(INITIATE_PURCHASE);
	const handleContinueClick = () => {
		const num = getCountryPhoneCode(info?.country_code)?.concat(phoneNumber);
		mutate({ reference: transactionRef, phone_number: num, mode: "sms" });
	};

	return (
		<div className="flex flex-col">
			<LCNavbar hasBack handleBackClick={handleBackClick} />
			<div className="flex flex-col gap-3 text-center">
				<LCTitleSubtitle title="Verify phone number" />
				<LCInput
					type="tel"
					label="Phone Number"
					placeholder="Enter phone number"
					value={phoneNumber}
					onChange={(e) => {
						setPhoneNumber(e.target.value);
					}}
				/>
				<div className="mt-12">
					<LCButton type="submit" text="Continue" onClick={handleContinueClick} isDisabled={phoneNumber.length < 8} isLoading={isLoading} />
				</div>
			</div>
		</div>
	);
};

export default ProvidePhone;
