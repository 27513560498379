import LCAccountDetails from "../components/LCAccountDetails";
import LCButton from "../components/LCButton";
import LCScreenLoader from "../components/LCScreenLoader";
import { VERIFICATION_STATUS, WAIT_FOR_PAYMENT_TRANSFER } from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import { commalize } from "../utility";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAccountDetails, getTransactionDetails } from "src/api";
import { TRANSACTION_STATE } from "src/constants/transaction";
import { useEffect } from "react";

const SendToBankAccount = () => {
	const { setCurrentScreen, setErrorMessage } = useAppContext();
	const { info, transactionRef, checkoutLinkRef } = useTransactionContext();

	const { data, isLoading } = useQuery({
		queryKey: ["account-details", transactionRef],
		queryFn: () => getAccountDetails(transactionRef),
		onError: ({ message }: any) => setErrorMessage(message),
	});
	const { mutate: mutateTransactionInfo } = useMutation(getTransactionDetails, {
		onSuccess: ({ data }) => {
			if (data?.screen_state === TRANSACTION_STATE.PAYMENT_RECEIVED) {
				setCurrentScreen(VERIFICATION_STATUS);
				return;
			}
		},
	});

	useEffect(() => {
		const interval = setInterval(() => {
			mutateTransactionInfo({ payment_link_ref: checkoutLinkRef });
		}, 10000);

		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePaymentMadeClick = () => setCurrentScreen(WAIT_FOR_PAYMENT_TRANSFER);

	if (isLoading) return <LCScreenLoader info="Fetching bank details" />;

	return (
		<div className="flex flex-col">
			<LCNavbar />
			<div className="flex flex-col items-center justify-between w-full">
				<div className="w-full space-y-2 text-center">
					<h1 className="text-base font-semibold text-black">
						Pay {info?.sender_currency} {commalize(info?.payment_amount)}
					</h1>
					<p className="text-xs py-2 px-3 w-full bg-[#FFF7EB] rounded-lg mb-3 grotesk">
						Make a <span className="text-[#875100] font-bold">single bank transfer</span> to this account from your <span className="text-[#875100] font-bold">personal bank account</span>.
					</p>
					<div>
						<LCAccountDetails
							bankName={data?.data?.bank_name}
							accountNumber={data?.data?.account_number}
							accountName={data?.data?.account_name}
							refCode={data?.data?.optional_bank_ref}
						/>
						<div className="flex items-center justify-center mt-4 space-x-1">
							<i className="ri-timer-2-line text-orange"></i>
							<p className="text-xs text-black">
								Should be confirmed in <span className="font-semibold">5 mins</span>{" "}
							</p>
						</div>
					</div>
					<LCButton text="I have made payment" onClick={handlePaymentMadeClick} />
				</div>
			</div>
		</div>
	);
};

export default SendToBankAccount;
