import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { sendMomoOTP } from "src/api";
import LCButton from "src/components/LCButton";
import LCInput from "src/components/LCInput";
import LCTitleSubtitle from "src/components/LCTitleSubtitle";
import { PAY_WITH_MOBILE, WAIT_FOR_PAYMENT_MOMO } from "src/constants/screen";
import LCNavbar from "src/containers/LCNavbar";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";

const MomoOTP = () => {
	const { setCurrentScreen, setErrorMessage } = useAppContext();
	const { transactionRef } = useTransactionContext();

	const [verificationCode, setVerificationCode] = useState("");

	const { mutate, isLoading } = useMutation(sendMomoOTP, {
		onSuccess: () => setCurrentScreen(WAIT_FOR_PAYMENT_MOMO),
		onError: (error: any) => setErrorMessage(error.message),
	});

	const handleBackClick = () => setCurrentScreen(PAY_WITH_MOBILE);
	const handleContinueClick = () => {
		mutate({
			otp: verificationCode,
			reference: transactionRef,
		});
	};

	return (
		<div>
			<LCNavbar hasBack handleBackClick={handleBackClick} />
			<div className="flex flex-col gap-6">
				<div className={`text-center w-full`}>
					<LCTitleSubtitle title="Confirm OTP" subtitle="Kindly input the OTP sent to your phone number" />
				</div>
				<LCInput
					type="number"
					label="OTP"
					placeholder="Enter OTP"
					value={verificationCode}
					onChange={(e) => {
						setVerificationCode(e.target.value);
					}}
				/>
				<LCButton type="submit" text="Continue" onClick={handleContinueClick} isDisabled={!verificationCode} isLoading={isLoading} />
			</div>
		</div>
	);
};

export default MomoOTP;
