import { ReactNode, createContext, useContext, useState } from "react";
import { INITIATE_PURCHASE } from "src/constants/screen";
import chroma from "chroma-js";

type AppColour = {
	default: string;
	dark: string;
	darker: string;
	light: string;
	disabled: string;
};

const AppContext = createContext<{
	errorMessage: string | undefined;
	setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
	toastMessage: string | undefined;
	setToastMessage: React.Dispatch<React.SetStateAction<string>>;
	isMenuOpen: boolean;
	setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setAppColour: React.Dispatch<React.SetStateAction<AppColour>>;
	setIsPopup: React.Dispatch<React.SetStateAction<boolean>>;
	currentScreen: string;
	setCurrentScreen: (v: string) => void;
	previousScreen: string;
	colour: {
		default: string;
		dark: string;
		darker: string;
		light: string;
		disabled: string;
	};
	isPopup: boolean;
}>({
	errorMessage: undefined,
	setErrorMessage: () => {},
	toastMessage: undefined,
	setToastMessage: () => {},
	isMenuOpen: false,
	setIsMenuOpen: () => {},
	setAppColour: () => {},
	setIsPopup: () => {},
	currentScreen: INITIATE_PURCHASE,
	setCurrentScreen: () => {},
	previousScreen: "",
	colour: {
		default: "#5551FF",
		dark: "#3A37CD",
		darker: "#1C1A68",
		light: "#F4F3FF",
		disabled: chroma("#5551FF").alpha(0.7).hex(),
	},
	isPopup: false,
});

const AppContextProvider = ({ children }: { children: ReactNode }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [currentScreen, setCurrentScreenO] = useState(INITIATE_PURCHASE);
	const [previousScreen, setPreviousScreen] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [toastMessage, setToastMessage] = useState("");
	const [appColour, setAppColour] = useState<AppColour>({
		default: "#5551FF",
		dark: "#3A37CD",
		darker: "#1C1A68",
		light: "#F4F3FF",
		disabled: chroma("#5551FF").alpha(0.7).hex(),
	});
	const [isPopup, setIsPopup] = useState(false);

	const setCurrentScreen = (value: string) => {
		setPreviousScreen(currentScreen);
		setCurrentScreenO(value);
	};

	return (
		<AppContext.Provider
			value={{
				isMenuOpen,
				errorMessage,
				toastMessage,
				setIsMenuOpen,
				setErrorMessage,
				setToastMessage,
				currentScreen,
				previousScreen,
				setCurrentScreen,
				colour: appColour,
				isPopup: isPopup,
				setAppColour,
				setIsPopup,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppContextProvider;

export const useAppContext = () => useContext(AppContext);
