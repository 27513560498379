import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { sendMessageToParent } from "src/utility";
import { useAppContext } from "./AppContext";
import LCWidgetContainer from "src/components/LCWidgetContainer";
import ErrorBoundary from "src/screens/ErrorBoundary";
import { useMutation } from "@tanstack/react-query";
import { getTransactionDetails } from "src/api";
import TransactionWarning from "src/screens/TransactionWarning";
import LCScreenLoader from "src/components/LCScreenLoader";
import { setInStorage } from "src/utility/storage";
import {
	MOMO_OTP,
	ORDER_INFO,
	PAY_WITH_MOBILE,
	SEND_TO_ACCOUNT,
	TRANSACTION_WARNING,
	VERIFICATION_STATUS_FAILED,
	WAIT_FOR_PAYMENT_MOMO,
	WAIT_FOR_PAYMENT_TRANSFER,
} from "src/constants/screen";
import { TRANSACTION_STATE } from "src/constants/transaction";

const TransactionContext = createContext<{
	info: any;
	submittedDetails: any;
	transactionRef: string;
	checkoutLinkRef: string;
	setInfo: React.Dispatch<React.SetStateAction<any>>;
	setSubmittedDetails: React.Dispatch<React.SetStateAction<any>>;
}>({
	info: {},
	transactionRef: "",
	checkoutLinkRef: "",
	setInfo: () => {},
	submittedDetails: {},
	setSubmittedDetails: () => {},
});

interface IComponentToRender {
	children: JSX.Element;
	checkoutLinkRef: string;
	isLoading: boolean;
	isError: boolean;
}

const TransactionContextProvider = ({ children }: { children: JSX.Element }) => {
	const location = useLocation();
	const { setErrorMessage, errorMessage, toastMessage, setToastMessage, currentScreen, setCurrentScreen, isPopup, setIsPopup } = useAppContext();

	const [submittedDetails, setSubmittedDetails] = useState({});
	const [transactionInfo, setTransactionInfo] = useState<any>({});
	const [checkoutLinkRef, setCheckoutLinkRef] = useState(location.pathname.substring(1));
	const [transactionRef, setTransactionRef] = useState("");

	const { mutate, isLoading, isError } = useMutation(getTransactionDetails, {
		onSuccess: ({ data }) => {
			setTransactionRef(data.reference);
			setInStorage("key", data.public_key);
			setTransactionInfo(data);
		},
	});

	useEffect(() => {
		sendMessageToParent({ key: "pay-confirmIAmAPopup" });
	}, []);

	useEffect(() => {
		function getMessageFromParent(event: MessageEvent<any>) {
			//  const origin = event.origin;
			if (typeof event.data == "object") {
				if (event.data.nature === "popup") {
					if (!isPopup) {
						setIsPopup(true);
					}
				}
				if (event.data.call === "sendError") {
					setErrorMessage(event.data.value);
				}
				if (event.data.call === "sendCheckoutRef") {
					setCheckoutLinkRef(event.data.value);
				}
			}
		}
		window.addEventListener("message", getMessageFromParent);

		return () => {
			window.removeEventListener("message", getMessageFromParent);
		};
	}, [isPopup, setErrorMessage, setIsPopup]);

	useEffect(() => {
		if (!errorMessage?.trim().length || errorMessage === "Unable to fetch transaction, kindly refresh") return;
		const timeout = setTimeout(() => {
			setErrorMessage("");
		}, 3000);

		return () => {
			clearTimeout(timeout);
		};
	}, [errorMessage, setErrorMessage]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setToastMessage("");
		}, 2000);

		return () => {
			clearTimeout(timeout);
		};
	}, [setToastMessage, toastMessage]);

	useEffect(() => {
		mutate({ payment_link_ref: checkoutLinkRef });
	}, [checkoutLinkRef, currentScreen, mutate]);

	useEffect(() => {
		if (transactionInfo?.screen_state === TRANSACTION_STATE.ABANDONED) {
			setCurrentScreen(TRANSACTION_WARNING);
			return;
		}
		if (
			transactionInfo?.screen_state === TRANSACTION_STATE.AWAITING_PAYMENT &&
			transactionInfo?.payment_type === "bank_account" &&
			currentScreen !== SEND_TO_ACCOUNT
		) {
			setCurrentScreen(WAIT_FOR_PAYMENT_TRANSFER);
			return;
		}
		if (
			transactionInfo?.screen_state === TRANSACTION_STATE.AWAITING_PAYMENT &&
			transactionInfo?.payment_type === "mobile_money" &&
			currentScreen !== PAY_WITH_MOBILE &&
			currentScreen !== MOMO_OTP
		) {
			setCurrentScreen(WAIT_FOR_PAYMENT_MOMO);
			return;
		}
		if (transactionInfo?.screen_state === TRANSACTION_STATE.PAYMENT_RECEIVED) {
			setCurrentScreen(ORDER_INFO);
			return;
		}
		if (transactionInfo?.screen_state === TRANSACTION_STATE.FAILED) {
			setCurrentScreen(VERIFICATION_STATUS_FAILED);
			return;
		}
	}, [currentScreen, setCurrentScreen, transactionInfo]);

	return (
		<TransactionContext.Provider
			value={{
				info: transactionInfo,
				setInfo: setTransactionInfo,
				submittedDetails,
				setSubmittedDetails,
				transactionRef,
				checkoutLinkRef,
			}}
		>
			<LCWidgetContainer transaction={transactionInfo} transactionRef={transactionRef}>
				<ErrorBoundary>
					<ComponentToRender isLoading={isLoading} checkoutLinkRef={checkoutLinkRef} isError={isError}>
						{children}
					</ComponentToRender>
				</ErrorBoundary>
			</LCWidgetContainer>
		</TransactionContext.Provider>
	);
};

const ComponentToRender = ({ children, checkoutLinkRef, isLoading, isError }: IComponentToRender) => {
	if (!checkoutLinkRef) return <TransactionWarning errorMessage="Invalid transaction ref" handleRetry={undefined} />;

	if (isLoading) return <LCScreenLoader />;

	if (isError) return <TransactionWarning errorMessage="Couldn't initialize transaction" handleRetry={undefined} />;

	return children;
};

export default TransactionContextProvider;

export const useTransactionContext = () => useContext(TransactionContext);
