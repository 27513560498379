import axios from "axios";
import { getFromStorage } from "./utility/storage";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.log(error);
    throw new Error(error.response.data.msg || error.response.data.message);
  }
);

api.interceptors.request.use((config) => {
  config.headers["x-auth-token"] = `${getFromStorage("key")}`;
  return config;
}, undefined);

export const getTransactionDetails = (data: { payment_link_ref: string }) => api.post("/v1/checkout/transaction-details", data)

export const sendOTP = (data: { reference: string, phone_number: string | undefined, mode: "whatsapp" | "sms" }) => api.post("/v1/checkout/phone/verify", data)

export const verifyOTP = (data: { reference: string, otp: string }) => api.post("/v1/checkout/phone/verify/complete", data)

export const verifyBVN = (data: { reference: string, bvn: string }) => api.post("/v1/checkout/bvn/verify", data)

export const getSupportedMomo = (params: { currency: string, country_code: string }) => api.get("/v1/checkout/supported-momo", { params })

export const chargeMomo = (data: { reference: string, phone_number: string, phone_network: string }) => api.post("/v1/checkout/charge-phone", data)

export const getAccountDetails = (reference: string) => api.get(`/v1/checkout/bank-account/${reference}`)

export const sendMomoOTP = (data: { reference: string, otp: string }) => api.post(`/v1/checkout/submit-momo-otp`, data)
