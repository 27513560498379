import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { chargeMomo, getSupportedMomo } from "src/api";
import LCButton from "src/components/LCButton";
import LCDropdown from "src/components/LCDropdownNormal";
import LCInput from "src/components/LCInput";
import LCOverlay from "src/components/LCOverlay";
import LCScreenLoader from "src/components/LCScreenLoader";
import LCTitleSubtitle from "src/components/LCTitleSubtitle";
import { INITIATE_PURCHASE, MOMO_OTP, PROVIDE_BVN, PROVIDE_PHONE, WAIT_FOR_PAYMENT_MOMO } from "src/constants/screen";
import LCNavbar from "src/containers/LCNavbar";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";
import { getCountryPhoneCode } from "src/utility";

const PayWithMobile = () => {
	const { setCurrentScreen, setErrorMessage } = useAppContext();
	const { transactionRef, info } = useTransactionContext();

	const [networkProvider, setNetworkProvider] = useState<any>("");
	const [phoneNumber, setPhoneNumber] = useState("");

	const { mutate, isLoading: isChargingMomo } = useMutation(chargeMomo, {
		onSuccess: (data) => {
			if (data?.data?.is_otp_required) return setCurrentScreen(MOMO_OTP);
			return setCurrentScreen(WAIT_FOR_PAYMENT_MOMO);
		},
		onError: ({ message }: any) => setErrorMessage(message),
	});

	const { data, isLoading: isSupportedMomoLoading } = useQuery({
		queryKey: ["supported-momo", info.sender_currency, info.country_code],
		queryFn: () => getSupportedMomo({ currency: info.sender_currency, country_code: info.country_code }),
	});

	const handleBackClick = () => setCurrentScreen(INITIATE_PURCHASE);
	const handleContinueClick = () =>
		mutate({
			reference: transactionRef,
			phone_network: networkProvider,
			phone_number: getCountryPhoneCode(info?.country_code).concat(phoneNumber),
		});

	useEffect(() => {
		if (info.phone_required) {
			setCurrentScreen(PROVIDE_PHONE);
			return;
		}
		if (info.bvn_required) {
			setCurrentScreen(PROVIDE_BVN);
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isSupportedMomoLoading) return <LCScreenLoader />;

	return (
		<div>
			{isChargingMomo && (
				<LCOverlay
					message={
						<div className="text-[1rem]">
							<p className="">Kindly continue with the prompt on your phone.</p>
							<p className="mt-4">Do not close modal</p>
						</div>
					}
				/>
			)}
			<LCNavbar hasBack handleBackClick={handleBackClick} />
			<div className="flex flex-col gap-6">
				<div className={`text-center w-full`}>
					<LCTitleSubtitle title="Mobile money details" subtitle="Kindly provide your phone number and network provider" />
				</div>
				<LCDropdown
					onOptionChange={(value) => setNetworkProvider(value.id)}
					dropdownTitle={"Network Provider"}
					dropdownPlaceholder={"Select provider"}
					options={data?.data?.map((provider: any) => ({ id: provider.id, name: provider.name }))}
					isDisabled={false}
				/>
				<LCInput
					type="tel"
					label="Phone Number"
					placeholder="234567890"
					value={phoneNumber}
					onChange={(e) => {
						setPhoneNumber(e.target.value);
					}}
				/>
				<LCButton
					type="submit"
					text="Continue"
					onClick={handleContinueClick}
					isDisabled={!networkProvider || phoneNumber.length < 8}
					isLoading={isChargingMomo}
				/>
			</div>
		</div>
	);
};

export default PayWithMobile;
