/* eslint-disable import/no-unresolved */
import { useState } from "react";
import LCDropdownList from "./LCDropdownList";
import LCDropdownListSearchable from "./LCDropdownListSearchable";

export type DropDownDatatype = {
	id?: number | string;
	name: string;
	image: string;
};

const LCDropdown = ({
	dropdownTitle,
	dropdownPlaceholder,
	options,
	defaultOption,
	onOptionChange,
	isDisabled,
	isSearchable = false,
}: {
	dropdownTitle: string;
	dropdownPlaceholder?: string;
	defaultOption?: DropDownDatatype | null;
	options: DropDownDatatype[];
	isSearchable?: boolean;
	onOptionChange: (v: DropDownDatatype) => void;
	isDisabled: boolean;
}) => {
	const [shouldShowDropdownList, setShouldShowDropdownList] = useState(false);
	const [selectedOption, setSelectedOption] = useState(defaultOption); //This should come from the top

	const toggleDropdownList = () => {
		setShouldShowDropdownList(!shouldShowDropdownList);
	};
	const handleOptionSelect = (v: DropDownDatatype) => {
		if (isDisabled) {
			return;
		}
		onOptionChange(v);
		setSelectedOption(v);
		setShouldShowDropdownList(false);
	};

	return (
		<div className="flex flex-col gap-1 pb-1 border-b border-black-20">
			<label className="text-xs font-medium text-black-40">{dropdownTitle}</label>
			<button className="flex items-center justify-between w-full space-x-0 cursor-pointer" onClick={toggleDropdownList} disabled={isDisabled}>
				{selectedOption ? (
					<div className="flex items-center space-x-1">
						{!!selectedOption?.image && (
							<div className={`w-4 h-4 bg-black-20 rounded-full flex items-center justify-center`}>
								<img src={selectedOption?.image} alt="currency" className="w-full h-full rounded-full" />
							</div>
						)}
						<p className="text-sm font-semibold text-black bg-transparent outline-none">{selectedOption?.name}</p>
					</div>
				) : (
					<p className="text-sm font-semibold bg-transparent outline-none text-black-20">{dropdownPlaceholder}</p>
				)}

				{isDisabled ? null : <i className="block ri-arrow-down-s-fill" style={{ color: "black" }}></i>}
			</button>
			{shouldShowDropdownList &&
				(isSearchable ? (
					<LCDropdownListSearchable
						searchLabel={dropdownTitle}
						searchPlaceholder={dropdownPlaceholder || "Select"}
						allOptions={options}
						onOptionSelect={handleOptionSelect}
						onClose={setShouldShowDropdownList}
					/>
				) : (
					<LCDropdownList options={options} title={dropdownTitle} onOptionSelect={handleOptionSelect} onClose={setShouldShowDropdownList} />
				))}
		</div>
	);
};

export default LCDropdown;
