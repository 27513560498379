import { useEffect } from "react";
import LCProcessStatus from "../components/LCProcessStatus";
import { useAppContext } from "src/context/AppContext";
import { ORDER_INFO } from "src/constants/screen";

const VerificationStatus = ({ status }: { status: boolean }) => {
	const { setCurrentScreen } = useAppContext();

	useEffect(() => {
		const timeOut = setTimeout(() => {
			setCurrentScreen(ORDER_INFO);
		}, 5000);

		return () => clearTimeout(timeOut);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="w-full h-[396px] flex flex-col items-center justify-center">
			<LCProcessStatus
				isSuccess={status}
				successTitle="Verification Successful"
				failTitle="Verification Failed"
				successSubtitle={<p>You'll be redirected shortly...</p>}
				failSubtitle="We couldn’t verify you. Kindly check your details and retry."
			/>
		</div>
	);
};

export default VerificationStatus;
