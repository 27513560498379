import { copyToClipboard } from "../utility";
import { useAppContext } from "src/context/AppContext";

interface PropsType {
	bankName: string;
	accountName: string;
	accountNumber: string;
	refCode: string;
}
const LCAccountDetails = ({ bankName, accountNumber, accountName, refCode }: PropsType) => {
	const { colour, setToastMessage } = useAppContext();

	return (
		<div className="text-center rounded-lg" style={{ backgroundColor: colour.light }}>
			<div className="p-4 border-b-[1.5px] border-black-10">
				<p className="mb-1 text-sm font-bold">{bankName}</p>
				<button
					onClick={() => copyToClipboard(accountNumber).then(() => setToastMessage("Copied!"))}
					className="flex items-center justify-center mx-auto mb-2 space-x-1"
				>
					<p className="text-xl font-bold" style={{ color: colour.dark }}>
						{accountNumber}
					</p>
					<i className="ri-file-copy-fill ri-md text-black-40" />
				</button>
				<p className="text-xs text-black">{accountName}</p>
			</div>
			<div className="py-3 px-4 flex w-full justify-between items-center mb-[2px]">
				<p className="text-xs font-bold text-black">Reference (optional):</p>
				<button
					onClick={() => copyToClipboard(refCode).then(() => setToastMessage("Copied!"))}
					className="flex items-center justify-center ml-auto space-x-1"
				>
					<p className="text-xs font-semibold text-black-80">{refCode}</p>
					<i className="ri-file-copy-fill ri-sm text-black-40" />
				</button>
			</div>
		</div>
	);
};

export default LCAccountDetails;
