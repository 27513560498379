export const hasStorageAccess = (() => {
  try {
    if ((localStorage || sessionStorage) && true)
      return true;
  } catch (error) {
    return false;
  }
})();

// GET
export const getJsonFromStorage = (key: string) =>
  JSON.parse(sessionStorage.getItem(key) || "{}");

export const getFromStorage = (key: string) => sessionStorage.getItem(key);

export const getFromStorageLocal = (key: string) => localStorage.getItem(key);

// CLEAR
export const clearFromSession = () => sessionStorage.clear();

// SET
export const setJsonInStorage = (key: string, value: string) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const setInStorage = (key: string, value: string) =>
  sessionStorage.setItem(key, value);

export const setInStorageLocal = (key: string, value: string) =>
  localStorage.setItem(key, value);
