import chroma from "chroma-js";
import { ReactNode } from "react";
import { useAppContext } from "src/context/AppContext";

export const LCClickableListItem = ({
	icon,
	title,
	extra,
	isDisabled = false,
	handleClick = () => {},
}: {
	icon: string;
	title: string;
	extra?: ReactNode;
	isDisabled?: boolean;
	handleClick: () => void;
}) => {
	const { colour } = useAppContext();
	return (
		<button onClick={handleClick} className="w-full p-2">
			<div
				className={`px-4 py-3 flex items-center justify-between rounded-md`}
				style={{ backgroundColor: chroma(colour.default).alpha(0.1).hex() }}
				role={"button"}
			>
				<div className="flex items-center space-x-1">
					<i className={`${icon}`} style={{ color: chroma(colour.dark).alpha(0.5).hex() }}></i>
					<p className={`text-sm font-semibold`} style={{ color: isDisabled ? chroma(colour.darker).alpha(0.4).hex() : colour.darker }}>
						{title}
					</p>
				</div>
				<div>{extra}</div>
			</div>
		</button>
	);
};

export const LCClickableList = ({ children }: { children: ReactNode }) => {
	return <div className="divide-y clickable-list divide-purple">{children}</div>;
};
