import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { getTransactionDetails } from "src/api";
import { WhiteSpinner } from "src/assets/icons/others";
import { SEND_TO_ACCOUNT, VERIFICATION_STATUS } from "src/constants/screen";
import { TRANSACTION_STATE } from "src/constants/transaction";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";
import { sendMessageToParent } from "src/utility";

const WaitForPaymentTransfer = () => {
	const { setCurrentScreen, isPopup } = useAppContext();
	const { checkoutLinkRef, info, transactionRef } = useTransactionContext();

	const { mutate: mutateTransactionInfo } = useMutation(getTransactionDetails, {
		onSuccess: ({ data }) => {
			if (data?.screen_state === TRANSACTION_STATE.PAYMENT_RECEIVED) {
				setCurrentScreen(VERIFICATION_STATUS);
				return;
			}
		},
	});

	useEffect(() => {
		const interval = setInterval(() => {
			mutateTransactionInfo({ payment_link_ref: checkoutLinkRef });
		}, 10000);

		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const buttonClick = () => setCurrentScreen(SEND_TO_ACCOUNT);

	const handleModalCloseClick = () => {
		if (!window.confirm("Are you sure you want to close this widget?")) return;
		if (isPopup) {
			sendMessageToParent({ key: "pay-closeModal" });
		} else {
			window.location = `${info?.callback_url || "https://localramp.co"}?ref=${transactionRef}` as string & Location;
		}
	};

	return (
		<>
			<div className="flex flex-col gap-6 items-center justify-center h-full text-center min-h-[302px]">
				<p className="mb-6 text-lg text-black-80">Kindly wait while we confirm your payment. It won’t take long</p>
				<div className="animate-spin">
					<WhiteSpinner className="w-14 h-14 text-[#44A55E]" />
				</div>
				<button className="text-sm font-semibold rounded-md text-black-80 bg-[#F2F2F2] py-2 px-4" onClick={buttonClick}>
					view account details
				</button>
				<p className="pb-8 text-sm text-black-80">
					If you can’t wait, click <span className="text-purple">close</span> below. You’ll receive value once we receive your payment
				</p>
				<button className="text-sm font-semibold text-purple" onClick={handleModalCloseClick}>
					Close modal
				</button>
			</div>
		</>
	);
};

export default WaitForPaymentTransfer;
