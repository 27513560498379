import ReactDOM from "react-dom/client";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AppError from "./screens/AppError";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
});

const APP_ROUTES = createBrowserRouter([
	{
		index: true,
		element: <App />,
		errorElement: <AppError />,
	},
	{
		path: "/:ref",
		element: <App />,
		errorElement: <AppError />,
	},
]);

root.render(
	<QueryClientProvider client={queryClient}>
		<RouterProvider router={APP_ROUTES} />
	</QueryClientProvider>
);
