export const INITIATE_PURCHASE = "initiate_purchase";
export const PROVIDE_PHONE = "pay_with_transfer";
export const SEND_TO_ACCOUNT = "send_to_account";
export const PAY_WITH_MOBILE = "pay_with_mobile";
export const WAIT_FOR_PAYMENT_TRANSFER = "wait_for_payment_transfer";
export const WAIT_FOR_PAYMENT_MOMO = "wait_for_payment_momo";
export const MOMO_OTP = "momo_otp";
export const ORDER_INFO = "order_info";

export const PROVIDE_BVN = "provide_bvn";
export const VERIFICATION_CODE = "verification_code";
export const VERIFICATION_STATUS = "verification_status";
export const VERIFICATION_STATUS_FAILED = "verification_status_failed";
export const PAYMENT_STATUS = "payment_status";

export const TRANSACTION_WARNING = "transaction_warning";
export const APP_ERROR = "app_error";
