import { useState } from "react";
import LCButton from "../components/LCButton";
import LCInput from "../components/LCInput";
import { INITIATE_PURCHASE, PAY_WITH_MOBILE, SEND_TO_ACCOUNT } from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import { useAppContext } from "src/context/AppContext";
import { useTransactionContext } from "src/context/TransactionContext";
import { verifyBVN } from "src/api";
import { useMutation } from "@tanstack/react-query";

const ProvideBVN = () => {
	const { setCurrentScreen, setErrorMessage } = useAppContext();
	const { info, transactionRef } = useTransactionContext();

	const { mutate: verifyBVNMutate, isLoading: isVerifyingBVN } = useMutation(verifyBVN, {
		onSuccess: () => {
			if (info?.payment_type === "bank_account") {
				return setCurrentScreen(SEND_TO_ACCOUNT);
			} else {
				return setCurrentScreen(PAY_WITH_MOBILE);
			}
		},
		onError: ({ message }: any) => setErrorMessage(message),
	});

	const [bvn, setBvn] = useState("");

	const handleContinueClick = () => verifyBVNMutate({ reference: transactionRef, bvn });

	const handleBack = () => setCurrentScreen(INITIATE_PURCHASE);
	const isFormValueValid = () => bvn.length === 11;

	return (
		<div className="flex flex-col pb-4">
			<LCNavbar hasBack handleBackClick={handleBack} />
			<div className="flex flex-col items-center justify-between w-full">
				<div className="w-full text-center">
					<div className="mb-4">
						<h1 className="mb-6 text-lg font-bold text-black">Kindly provide BVN</h1>
						<p className="w-full px-3 text-xs font-bold rounded-lg text-purple grotesk">This is one-time</p>
						<p className="m-0 text-xs grotesk text-black-80">
							It's a security measure to confirm your phone number matched the one on your BVN
						</p>
					</div>
				</div>

				<form className="w-full my-4">
					<div className="space-y-7 mb-14">
						<LCInput
							type="number"
							label="Bank Verification Number"
							placeholder="Enter BVN"
							value={bvn}
							onChange={(e) => {
								setBvn(e.target.value);
							}}
						/>
					</div>
					<LCButton
						type="submit"
						isLoading={isVerifyingBVN}
						isDisabled={!isFormValueValid()}
						text="Continue"
						onClick={handleContinueClick}
					/>
				</form>
			</div>
		</div>
	);
};

export default ProvideBVN;
