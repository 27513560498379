import { useEffect, useState } from "react";
import LCButton from "../components/LCButton";
import LCInputPin from "../components/LCInputPin";
import { PAY_WITH_MOBILE, PROVIDE_BVN, SEND_TO_ACCOUNT } from "../constants/screen";
import LCNavbar from "../containers/LCNavbar";
import { maskPhoneNumber } from "../utility";
import { useAppContext } from "src/context/AppContext";
import { getTransactionDetails, sendOTP, verifyOTP } from "src/api";
import { useMutation } from "@tanstack/react-query";
import { useTransactionContext } from "src/context/TransactionContext";
import { useCountDown } from "src/hooks/useCountdown";
import LCScreenLoader from "src/components/LCScreenLoader";

interface IResendProps {
	setVerificationCode: React.Dispatch<React.SetStateAction<string>>;
	sendCode: (mode: "sms" | "whatsapp") => void;
	counter: number;
	isCounting: boolean;
	isResendingOTP: boolean;
}

const VerificationCode = () => {
	const { previousScreen, setCurrentScreen, setErrorMessage, setToastMessage } = useAppContext();
	const { transactionRef, info, checkoutLinkRef } = useTransactionContext();
	const [counter, start, , , isCounting] = useCountDown(30);

	const [verificationCode, setVerificationCode] = useState("");

	const isVerificationCodeValid = () => verificationCode.trim().length === 6;

	const { mutateAsync, isLoading } = useMutation(getTransactionDetails, {});
	const { mutate: sendOTPMutate, isLoading: isResendingOTP } = useMutation(sendOTP, {
		onSuccess: () => {
			setToastMessage("OTP resent");
			start();
		},
		onError: ({ message }: any) => setErrorMessage(message),
	});
	const { mutate: verifyOTPMutate, isLoading: isVerifyingOTP } = useMutation(verifyOTP, {
		onSuccess: async () => {
			const { data: transactionInfo } = await mutateAsync({ payment_link_ref: checkoutLinkRef });
			if (transactionInfo.bvn_required) {
				setCurrentScreen(PROVIDE_BVN);
				return;
			}
			if (transactionInfo?.payment_type === "bank_account") {
				setCurrentScreen(SEND_TO_ACCOUNT);
				return;
			} else {
				setCurrentScreen(PAY_WITH_MOBILE);
				return;
			}
		},
		onError: ({ message }: any) => setErrorMessage(message),
	});

	const handleContinueClick = () => verifyOTPMutate({ reference: transactionRef, otp: verificationCode });

	const handleBackClick = () => setCurrentScreen(previousScreen);

	const sendCode = (mode: "whatsapp" | "sms") => sendOTPMutate({ reference: transactionRef, phone_number: info?.phone_number, mode });

	useEffect(() => {
		start();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoading) return <LCScreenLoader />;

	return (
		<div className="flex flex-col">
			<LCNavbar hasBack handleBackClick={handleBackClick} />
			<div className="flex flex-col items-center justify-between w-full">
				<div className="w-full mb-10 text-center">
					<div className="mb-4 space-y-2">
						<h1 className="text-base font-semibold text-black">Verification Code</h1>
						<p className="text-xs text-black-80 w-full max-w-[171px] mx-auto">
							We sent a 6-digit code to <br />
							<b>{maskPhoneNumber(info?.phone_number)}</b>
						</p>
					</div>
					<div className="space-y-4">
						<LCInputPin length={6} onChange={setVerificationCode} />
						<div className="flex items-center justify-center space-x-1 text-xs">
							<p className="font-semibold text-black-80">Didn&apos;t receive it?</p>
							<ResendCode
								setVerificationCode={setVerificationCode}
								sendCode={sendCode}
								counter={counter}
								isCounting={isCounting}
								isResendingOTP={isResendingOTP}
							/>
						</div>
						<div className="flex items-center justify-center w-full">
							<ResendCodeWhatsApp
								setVerificationCode={setVerificationCode}
								sendCode={sendCode}
								counter={counter}
								isCounting={isCounting}
								isResendingOTP={isResendingOTP}
							/>
						</div>
					</div>
				</div>
			</div>
			<LCButton isDisabled={!isVerificationCodeValid()} isLoading={isVerifyingOTP} text="Continue" onClick={handleContinueClick} />
		</div>
	);
};

const ResendCodeWhatsApp = ({ setVerificationCode, sendCode, counter, isCounting, isResendingOTP }: IResendProps) => {
	const handleResendCode = () => {
		setVerificationCode("");
		sendCode("whatsapp");
	};

	if (isResendingOTP) return null;

	if (isCounting)
		return (
			<button disabled className="flex items-center justify-center space-x-1 text-sm outline-none text-[#29773E]">
				<i className="ri-whatsapp-line ri-lg"></i>
				<p className="font-semibold">Send code via WhatsApp</p>
			</button>
		);

	return (
		<button onClick={handleResendCode} className="flex items-center justify-center space-x-1 text-sm outline-none text-[#29773E]">
			<i className="ri-whatsapp-line ri-lg"></i>
			<p className="font-semibold">Send code via WhatsApp</p>
		</button>
	);
};

const ResendCode = ({ setVerificationCode, sendCode, counter, isCounting, isResendingOTP }: IResendProps) => {
	const { colour } = useAppContext();

	const handleResendCode = () => {
		setVerificationCode("");
		sendCode("sms");
	};

	if (isResendingOTP)
		return (
			<div>
				<div className="animate-spin">
					<i className="ri-loader-5-line" style={{ color: colour.default }} />
				</div>
			</div>
		);

	if (isCounting)
		return (
			<>
				<p className="font-medium" style={{ color: colour.default }}>
					Resend in {counter}s
				</p>
			</>
		);

	return (
		<button type="button" onClick={handleResendCode} className="font-medium border-none outline-none" style={{ color: colour.dark }}>
			Resend code
		</button>
	);
};

export default VerificationCode;
