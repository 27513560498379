import { useAppContext } from "src/context/AppContext";

const LCDescriptionItem = (props: { title: string; content: string | JSX.Element }) => {
	const { colour } = useAppContext();

	return (
		<div className="space-y-1 text-center">
			<p className="text-xs font-semibold" style={{ color: colour.default }}>
				{props.title}
			</p>
			<span className="text-xs font-bold block text-black-40">{props.content}</span>
		</div>
	);
};

export default LCDescriptionItem;
