import { useState } from "react";
import LCButton from "../components/LCButton";
import { PAY_WITH_MOBILE, PROVIDE_BVN, PROVIDE_PHONE, SEND_TO_ACCOUNT } from "../constants/screen";
import { useAppContext } from "src/context/AppContext";
import LCTitleSubtitle from "src/components/LCTitleSubtitle";
import { LCClickableListItem } from "src/components/LCClickableList";
import chroma from "chroma-js";
import { useTransactionContext } from "src/context/TransactionContext";
import { commalize, findCoinLogo } from "src/utility";

const InitiatePurchase = () => {
	const { setCurrentScreen, colour } = useAppContext();
	const { info } = useTransactionContext();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const proceedToNextScreen = () => {
		if (info?.phone_required) {
			setCurrentScreen(PROVIDE_PHONE);
			return;
		}
		if (info?.bvn_required) {
			setCurrentScreen(PROVIDE_BVN);
			return;
		}
		if (info?.payment_type === "bank_account") {
			setCurrentScreen(SEND_TO_ACCOUNT);
		} else {
			setCurrentScreen(PAY_WITH_MOBILE);
		}
	};

	const handleContinueClick = () => {
		setIsSubmitting(true);
		proceedToNextScreen();
	};

	return (
		<div className="mb-4">
			<div className={`flex flex-col gap-2 mb-2 text-center`}>
				<LCTitleSubtitle title="Make Payment" />
				<div className="space-y-1 text-[14px] text-black-80 my-3 bg-[#F0FDF4] p-2 rounded-lg">
					<div className="flex items-center justify-between">
						<p className="font-normal text-[#8f8f8f]">To pay</p>
						<p className="flex items-center gap-1 text-[#747474]">
							{info?.receiver_amount} USDT <img src={findCoinLogo({ coin: "USDT" })} alt="currency" className="w-4 h-4 rounded-full" />
						</p>
					</div>
					<div className="flex items-center justify-between">
						<p className="font-normal text-[#8f8f8f]">Processing fee</p>
						<p className="font-normal text-[#747474]">
							{info?.processor_fees?.type === "FLAT"
								? `${info?.sender_currency} ${commalize(Number(info?.processor_fees?.fee))}`
								: `${info?.sender_currency} ${commalize((Number(info?.processor_fees?.fee) / 100) * Number(info?.payment_amount))}`}
						</p>
					</div>
					<div className="flex items-center justify-between">
						<p className="font-normal text-[#8f8f8f]">Amount to send</p>
						<p className="font-semibold text-[#171717]">
							{info?.sender_currency} {commalize(Number(info?.payment_amount))}
						</p>
					</div>
				</div>
				{info?.payment_type === "bank_account" ? (
					<LCClickableListItem
						icon="ri-bank-line"
						title="Bank Transfer"
						extra={
							<p className="text-[12px]" style={{ color: chroma(colour.darker).alpha(0.6).hex() }}>
								Est time: 3 mins
							</p>
						}
						handleClick={() => {}}
					/>
				) : (
					<LCClickableListItem
						icon="ri-smartphone-line"
						title="Mobile Money"
						extra={
							<p className="text-[12px]" style={{ color: chroma(colour.darker).alpha(0.6).hex() }}>
								Est time: 3 mins
							</p>
						}
						handleClick={() => {}}
					/>
				)}
				<p className="mb-10 text-sm font-normal text-black-40">Proceed to make payment by clicking the button below</p>
			</div>
			<LCButton type="submit" text="Continue" onClick={handleContinueClick} isLoading={isSubmitting} />
		</div>
	);
};

export default InitiatePurchase;
