// import { useState } from "react";
import {
	INITIATE_PURCHASE,
	ORDER_INFO,
	VERIFICATION_CODE,
	APP_ERROR,
	PROVIDE_PHONE,
	PAY_WITH_MOBILE,
	PROVIDE_BVN,
	SEND_TO_ACCOUNT,
	VERIFICATION_STATUS,
	TRANSACTION_WARNING,
	VERIFICATION_STATUS_FAILED,
	WAIT_FOR_PAYMENT_MOMO,
	WAIT_FOR_PAYMENT_TRANSFER,
	MOMO_OTP,
} from "../constants/screen";
import AppError from "./AppError";
import { useAppContext } from "src/context/AppContext";
import InitiatePurchase from "./InitiatePurchase";
import OrderInfo from "./OrderInfo";
import ProvidePhone from "./ProvidePhone";
import VerificationCode from "./VerificationCode";
import ProvideBVN from "./ProvideBVN";
import PayWithMobile from "./PayWithMobile";
import SendToBankAccount from "./SendToBankAccount";
import WaitForPaymentTransfer from "./WaitForPaymentTransfer";
import VerificationStatus from "./VerificationStatus";
import TransactionWarning from "./TransactionWarning";
import WaitForPaymentMomo from "./WaitForPaymentMomo";
import MomoOTP from "./MomoOTP";

const ScreenManager = () => {
	const { currentScreen } = useAppContext();

	switch (currentScreen) {
		case INITIATE_PURCHASE:
			return <InitiatePurchase />;

		case PROVIDE_PHONE:
			return <ProvidePhone />;
		case VERIFICATION_CODE:
			return <VerificationCode />;
		case PROVIDE_BVN:
			return <ProvideBVN />;

		case SEND_TO_ACCOUNT:
			return <SendToBankAccount />;

		case PAY_WITH_MOBILE:
			return <PayWithMobile />;
		case MOMO_OTP:
			return <MomoOTP />;
		case WAIT_FOR_PAYMENT_TRANSFER:
			return <WaitForPaymentTransfer />;
		case WAIT_FOR_PAYMENT_MOMO:
			return <WaitForPaymentMomo />;

		case VERIFICATION_STATUS:
			return <VerificationStatus status />;
		case VERIFICATION_STATUS_FAILED:
			return <VerificationStatus status={false} />;

		case ORDER_INFO:
			return <OrderInfo />;

		case TRANSACTION_WARNING:
			return <TransactionWarning />;

		case APP_ERROR:
			return <AppError />;
		default:
			return <InitiatePurchase />;
	}
};

export default ScreenManager;
