import LCNavbar from "../containers/LCNavbar";
import LCDescriptionItem from "../components/LCDescriptionItem";
import { useTransactionContext } from "src/context/TransactionContext";
import { useAppContext } from "src/context/AppContext";
import { CheckedSuccess } from "src/assets/icons/others";
import { commalize } from "src/utility";

const OrderInfo = () => {
	const { colour } = useAppContext();

	const { info } = useTransactionContext();

	const handleHelpClick = () => {
		window.location.href = `mailto:support@localramp.co`;
	};

	return (
		<div className="flex flex-col">
			<LCNavbar />
			<div className="flex flex-col items-center">
				<CheckedSuccess />
				<h1 className="mb-3 text-lg font-medium text-center text-black">Your transaction is complete</h1>

				<div className="flex flex-col gap-2">
					<LCDescriptionItem title="Order ID" content={<p className="text-black-80">{info?.order_id}</p>} />
					<LCDescriptionItem
						title="Summary"
						content={
							<p className="text-black-80">
								You got {info?.receiver_amount} USDT for {info?.sender_currency} {commalize(Number(info?.sent_amount))}
							</p>
						}
					/>
					<LCDescriptionItem title={`Delivering USDT to`} content={<p className="text-black-80">{info?.merchant_name}</p>} />
				</div>

				<div className="bg-[#F2F2F2] pl-4 py-2 mt-7 rounded-lg flex items-center justify-start gap-[.5rem] w-full ">
					<i className="ri-lightbulb-line ri-lg" style={{ color: colour.default }}></i>
					<p className="text-[12px] text-black-40 text-balance">
						You may close this at any time. If there are any issues, we&apos;ll contact you via email. For more help{" "}
						<button onClick={handleHelpClick} className="font-semibold outline-none" style={{ color: colour.default }}>
							click here.
						</button>
					</p>
				</div>
			</div>
		</div>
	);
};

export default OrderInfo;
