import { LoaderSVG } from "src/assets/icons/others";

const LCScreenLoader = (props: { info?: string }) => {
	return (
		<div className="bg-white h-[280px] flex flex-col items-center justify-center">
			<div className="flex items-center justify-center">
				<div className="animate-spin mx-auto">
					<LoaderSVG />
				</div>
			</div>
			{props.info ? (
				<div className="w-full mt-6">
					<p className="text-center text-base font-semibold text-black">{props.info}</p>
				</div>
			) : undefined}
		</div>
	);
};

export default LCScreenLoader;
