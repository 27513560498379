import { ReactNode } from "react";
import { WhiteSpinner } from "src/assets/icons/others";

interface PropsType {
	message?: ReactNode;
}
const LCOverlay = ({ message }: PropsType) => {
	return (
		<div className="absolute top-0 left-0 z-40 w-full h-full bg-black/80 rounded-2xl">
			<div className="flex flex-col items-center justify-center w-full h-full space-y-4">
				<div className="mx-auto animate-spin">
					<WhiteSpinner className="text-white" />
				</div>
				{!!message && <p className="text-lg font-semibold text-center text-white">{message}</p>}
			</div>
		</div>
	);
};

export default LCOverlay;
